<template>
	<v-select 
		class="dropdown out" 
		:options="options" 
		label="name" 
		@search="searchLocations"
		:placeholder="placeholder"
		:filterable="false"
		v-model="value"
		@input="handleSelect"
		:loading="isLoading"

	>
		<template v-slot:no-options="{ search, searching }">
			<template v-if="searching">
				<span v-if="error != null">{{ error }}</span>
				<span v-else>No results found for <em>{{ search }}</em>.</span>
			</template>
			<template v-if="!searching">
				<div class="hide">
					<span v-if="placeholder != ''">Type to search for {{ placeholder }}</span>
					<span v-else>Type to search</span>
				</div>
			</template>
		</template>
		<template slot="option" slot-scope="option">
			<div class="option">
				<!-- Display the display property, or the name property if display is not available -->
				<div class="option-name" v-if="option.display" v-html="highlight(option.display, inputValue)"></div>
				<div class="option-name" v-else v-html="highlight(option.name, inputValue)"></div>
				<div v-if="option.type == 'country'" class="option-country">
					Country
				</div>
				<div v-else-if="option.country" class="option-country">
					{{ option.country }}
				</div>
				<!-- <pre>{{ option }}</pre> -->
			</div>

			<span v-if="option.type">
				<img v-if="option.type == 'tour'" class="option-icon" src="@/assets/search/tour-icon.png" alt="tour icon" width="20px"/>
				<img v-else-if="option.type == 'city'" class="option-icon" src="@/assets/search/city-icon.png" alt="tour icon" width="22px"/>
				<img v-else-if="option.type == 'keyword'" class="option-icon" src="@/assets/search-icon.png" alt="tour icon" width="14px"/>
				<img v-else class="option-icon" src="@/assets/search/location-icon.png" alt="location icon" width="20px"/>
			</span>
			<span v-else-if="option.location_type">
				<img v-if="option.location_type == 'city'" class="option-icon" src="@/assets/search/city-icon.png" alt="tour icon" width="22px"/>
				<img v-else-if="option.location_type == 'airport'" class="option-icon" src="@/assets/search/airport-icon.png" alt="tour icon" width="14px"/>
				<img v-else class="option-icon" src="@/assets/search/location-icon.png" alt="location icon" width="20px"/>
			</span>
		</template>
		<!-- <template slot="selected-option" slot-scope="option" v-if="option">
			<div class="selected d-center">
				{{ option }} test
			</div>
		</template> -->
	</v-select>
</template>
<style>
	.dropdown.v-select {
		width: 350px;
		height: 55px;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
		margin-bottom: 16px;
		display: block;
		position: relative;
	}
	.dropdown .hide {
		display: none;
		visibility: none;
	}
	.dropdown.v-select .vs__dropdown-toggle {
		height: 100%;
		width: 100%;
		border-bottom-color: rgba(60,60,60,.26);
		border-radius: 10px;
	}
	.dropdown.v-select .vs__search {
		color: #50555C;
	}
	.dropdown.v-select .vs__selected-options {
		padding-left: 45px;
		background-image: url('../assets/flights-search/flight-out-icon.png');
		background-position: left 16px center;
		background-repeat: no-repeat;
		background-size: 20px auto;
	}
	.dropdown.v-select.return .vs__selected-options {
		background-image: url('../assets/flights-search/flight-in-icon.png');
		background-size: 19px auto;
	}
	.dropdown.v-select.tour .vs__selected-options {
		background-image: url('../assets/tour-search/tour-icon.png');
		background-size: 17px auto;
	}
	.dropdown.v-select.cabin .vs__selected-options {
		background-image: url('../assets/cabin-icon.png');
		background-size: 24px auto;
		background-position: left 15px center;
	}
	.dropdown.v-select.location .vs__selected-options {
		background-image: url('../assets/search/location-icon.png');
		background-size: 24px auto;
		background-position: left 15px center;
	}
	.dropdown.v-select .vs__open-indicator {
		display: none;
	}
	.dropdown.v-select .vs__actions {
		width: 40px;
	}
	.dropdown.v-select .vs__actions .vs__clear {
		position: absolute;
		right: 8px;
	}
	.dropdown.v-select .vs__actions .vs__spinner {
		background-color: white;
	}
	/* .dropdown.cabin.v-select .vs__actions {
		display: flex;
		margin-right: 17px;
	} */

	.dropdown.v-select .vs__selected {
		font-weight: bold;
		color: #50555C;
		height: 100%;
		margin-top: 0;
	}
	.dropdown.v-select .option {
		/* position: relative; */
		/* top: 50%; */
		/* transform: translateY(50%); */
		max-width: 100%;
		
	}
	.dropdown.v-select .option-icon {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}
	.dropdown.v-select .option-name {
		color: #50555C;
		font-weight: bold;
		font-size: 16px;
		text-overflow: ellipsis;
		overflow: hidden;
	}
	.dropdown.v-select .option-name .highlight {
		color: #118AB2;
	}
	.dropdown.v-select .option-country {
		color: #979797;
		font-size: 13px;
	}
	.dropdown.v-select .vs__dropdown-option--highlight {
		background: #f9f9f9;
	}
	.dropdown.v-select .vs__dropdown-menu {
		/* padding-top: 0; */
		top: 100%;
		padding-top: 0px;
		padding-bottom: 0px;
		border: none;
		box-shadow: initial;
		max-height: 350px;
		/* border-bottom: 1px solid rgba(60,60,60,.26); */
		border-radius: 0;
		border-bottom: none;
	}
	
	.dropdown.v-select.vs--open:after {
		content: '';
		position: absolute;
		bottom: -350px; left: 0; right: 0;
		height: 350px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 70%, #ffffff 95%);
		z-index: 100000;
		pointer-events: none;

	}
	.dropdown.v-select .vs__dropdown-option {
		padding-bottom: 8px;
		padding-top: 8px;
		padding-right: 40px;
		position: relative;
		min-height: 70px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(60,60,60,.26);
	}

</style>
<script>
	import {  mapActions } from 'vuex'
	import { searchService } from '@/services/searchService.js'

	export default {
		props: {
			value: {}, // Binds to the v-model, does not need to be set
			placeholder: {
				type: String,
				default: "Country, City or Airport"
			},
			locationType: { // Options: all, countries, cities, airports, tours
				type: String,
				default: "all"
			},
		},
		data() {
			return { 
				inputValue: "",
				options: [],
				error: null,
				isLoading: false,
			}
		},
		methods: {
			...mapActions({
				fetchLocationOptions: 'flightsSearch/fetchLocationOptions'
			}),
			handleSelect(option) {
				if(option == null) {
					this.$emit("input", "");
					return;
				}

				let selection = Object.assign({}, option);; // Clone so that it doesn't update the name for the displayed options
				if(selection) {
					if(selection.code) {
						selection.name = selection.name + ' (' + selection.code + ')';
					}
					this.$emit("input", selection);
				}
			},
			searchLocations(query, loading) {
				this.inputValue = query; // Set the currently typed in value to variable

				loading(true);
				clearTimeout(this.debounceTimeout);
				this.debounceTimeout = setTimeout(() => {
					if(query != "") {
						searchService.fetchLocationOptions(query, this.locationType)
							.then(response => {
								this.options = response.data.data;
								if(this.locationType == "tours") {
									// Add the query as an option to the options
									this.options.unshift({
										name: query,
										display: "Keyword search: " + query,
										// code: null,
										// country: null,
										type: "keyword",
									});
								}
								this.error = null;
								loading(false);
							},
							error => {
								this.error = error;
								loading(false);
							});
					} else {
						loading(false);
					}
				}, 350);
			},
			highlight(text, highlight) {
				let index = text.toLowerCase().indexOf(highlight.toLowerCase());
				if(index >= 0) {
					return text.slice(0, index) + '<span class="highlight">' + text.slice(index, index + highlight.length) + '</span>' + text.slice(index + highlight.length);
				}
				return text;
			}
		},
		created () {
			// Ensure that the current value is selected from the options. This handles if the value only has the code, and not the name (etc) properties.
			// If no code or name property, assume that it needs to fetch full option from backend
			if(typeof this.value == "string") {
				if(this.value == "") {
					return; // If empty string, don't do anything so that it shows placeholder
				}
				// If just a string, build object
				this.value = {
					code: this.value,
				}
			}
			
			// If there's no name, fetch the full option from the backend
			if(!this.value.name && this.value.code) {
				let code = this.value.code;
				let locationType = this.value.location_type ? this.value.location_type : false;
				
				this.isLoading = true;

				// Set the name temporarily to 'loading' so that it isn't blank.
				this.value.name = 'Loading...';
				searchService.fetchLocationOptions(code, this.locationType)
					.then(response => {
						
						this.options = response.data.data;
						this.error = null;
						// this.value = this.options[0];
						// this.$emit("input", this.options[0]);

						// For auto-select, Filter to only include those that match the locationType
						let filteredResponse = this.options;
						if(locationType) {
							filteredResponse = this.options.filter(option => option.location_type == locationType);
						}
						if(filteredResponse.length > 0) {
							this.handleSelect(filteredResponse[0]); // Select first search result
						} else {
							this.handleSelect(null); // Don't select anything
						}
						this.isLoading = false;
					},
					error => {
						this.error = error;
						this.isLoading = false;
					});
			} 
		},

	}
</script>